@charset "UTF-8";
/* ==== CUSTOM UI ELEMENTS AND STYLES ====*/
/* ========================
INCLUDE STYLES AND COMPONENTS
===================== */
/* ================== HELPERS =================== */

body {
  font-family: 'Nunito', sans-serif;
}

.padd {
  margin-top: 35px;
}

.btn-primary.btn_cancel {
  background: #555 !important;
  border-color: #555 !important;
}

.avatar {
  color: #555;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 500;
}

.errorcs {
  margin-left: 250px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  width: 40%;
  display: flex;
  justify-content: center;
}


.table thead th {
  color: #adb5bd;
  font-weight: 400;
}

.table-lg td,
.table-lg th {
  padding: 0.8rem 1rem;
}

tbody td {
  border-bottom: 0;
}

tbody tr {
  border-bottom: 1px solid #e7e7e7;
}

tbody tr:hover {
  background-color: #ebf0fd !important;
}

a.disabled {
  pointer-events: none;
}

.w-35 {
  width: 55px !important;
  height: 50px;
}

.Header-green {
  background-color: #4fa607;
}


/* =================  DEFINED VARIABLES =================== */
.icon-xs,
.icon-sm,
.icon-md,
.icon-lg {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.icon-xs {
  width: 32px;
  height: 32px;
  line-height: 32px !important;
  font-size: 16px;
}

.icon-sm {
  width: 48px;
  height: 48px;
  line-height: 48px !important;
  font-size: 20px;
}

.media {
  align-items: flex-start;
}

.icon-md {
  width: 60px;
  height: 60px;
  line-height: 60px !important;
  font-size: 28px;
}

.icon-lg {
  width: 96px;
  height: 96px;
  line-height: 96px !important;
  font-size: 42px;
}

.img-avatar {
  border-radius: 100%;
  border: 3px solid #fff;
}

.img-xs,
.img-sm,
.img-md,
.img-lg {
  object-fit: cover;
  flex-shrink: 0;
  flex-grow: 0;
}

.img-xs {
  width: 40px;
  height: 40px;
}

.img-sm {
  width: 60px;
  height: 60px;
}

.img-md {
  width: 112px;
  height: 112px;
}

.img-lg {
  width: 196px;
  height: 196px;
}

.center-xy {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.center-y {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.center-x {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

/* ================== BASE =================== */
body {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}

body.offcanvas-active {
  overflow: hidden;
}

.main-wrap {
  margin-left: 260px;
  background-color: #F5F8FA;
  height: 100vh;
}

.content-main {
  display: grid;
  padding: 30px 16px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-aside {
  max-width: 290px;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  /* overflow-y: auto; */
  background-color: #313a46;
  box-shadow: none;
  z-index: 10;
  border-right: 0;
}

.navbar-aside .aside-top {
  padding: 9px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
}

.navbar-aside .aside-top .brand-wrap {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
}

.Sidebar {
  flex: 18%;

}

.Adjust {
  display: flex;
}

.Content {
  flex: 82%;
  /* max-width: 1140px; */
  padding-right: 20px;
}

/* .navbar-aside .aside-top .logo {
  height: 46px;
  width: auto;
  max-width: 200px;
} */
Link.logoheading {
  text-decoration: none;

}

.main-header {
  top: 0px;
  bottom: 0px;
  z-index: 10;
  position: sticky !important;
  padding-left: 3%;
  padding-right: 1.5rem !important;
  min-height: 72px;
  background-color: #fff;
  border-bottom: 1px solid rgba(108, 117, 125, 0.25);
}

.main-header .nav {
  align-items: center;
}

.main-header .nav-item>a {
  border-radius: 0.25rem;
  color: #333333;
  display: block;
  text-decoration: none;
}

.main-header .nav-link:hover {
  background-color: transparent;
  color: #f3711b;
}

.main-header .col-search {
  flex-grow: 0.3;
}

.main-header .col-nav {
  display: flex;
  align-items: center;
}

.nav-item img.rounded-circle {
  border: 2px solid #eee;
  height: 30px;
  width: 30px;
  object-fit: cover;
}

.user_image {
  margin-bottom: 0;
  margin-left: 20px;
  padding-left: 10px;
  border-left: 1px solid #efefef;
}

.nav-item img.rounded-circle:hover {
  border-color: transparent;
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.content-header .content-title {
  margin-bottom: 0;
  font-size: 24px;
  text-transform: capitalize;
}

.inner {
  padding: 1rem;
}

body.aside-mini .main-wrap {
  margin-left: 70px;
}

body.aside-mini .navbar-aside {
  max-width: 0px;
  overflow: visible;
  position: absolute;
  min-height: 100%;
  bottom: auto;
}

body.aside-mini .navbar-aside .aside-top {
  text-align: center;
}

body.aside-mini .navbar-aside .aside-top>div {
  flex-grow: 1;
}

body.aside-mini .navbar-aside .brand-wrap,
body.aside-mini .navbar-aside .logo {
  display: none;
}

body.aside-mini .menu-aside .menu-link {
  text-align: center;
}

body.aside-mini .menu-aside .menu-link .text {
  display: none;
}

body.aside-mini .menu-aside .menu-link .icon {
  margin-right: 0;
}

body.aside-mini .menu-aside .submenu {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: white;
  margin-left: 0;
  padding: 10px;
  position: absolute;
  left: 100%;
  top: 0;
  width: 180px;
}

body.aside-mini .menu-aside .menu-item {
  position: relative;
}

body.aside-mini .menu-aside .menu-item:hover .submenu {
  display: block;
  z-index: 10;
}

body.aside-mini .menu-aside .menu-item.has-submenu .menu-link:after {
  display: none;
}

.menu-aside {
  list-style: none;
  /* margin: 0;
  padding: 20px;
  margin-top: 20px; */
}

.nav-link .fa-chevron-down{
  font-size: 12px;
  float: right;
  margin-top: 6px;
}

.menu-aside a {
  display: block;
  text-decoration: none;
}

.menu-aside .menu-item {
  margin-bottom: 18px;
}

.menu-aside .menu-item .icon {
  color: #004f66;
  display: none;
  margin-right: 10px;
}

/* .menu-aside .active{
  background-color: #fff;
} */
/* .menu-aside .active .icon {
  color: #f3711b;
} */
/* .menu-aside .active .text {
  color: #000000 !important;
} */



/* .menu-aside .active {
  background-color: #ffffff;
} */
.menu-aside .menu-link {
  color: #ffffff;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.menu-aside .menu-link .text {
  margin-left: 14px;
}

.menu-aside .menu-link.active,
.menu-aside .menu-link:hover {
  color: #48a851;
}

.pimage {
  /* background: black; */
  object-fit: cover;
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

.menu-aside .menu-link .text {
  vertical-align: middle;
}

/* .menu-aside .menu-link:hover {
  transition: 0.2s linear;
  background-color: #f3711b;
} */

.menu-aside .menu-item:hover .menu-item {
  color: #fff;
}

.sucessmsg {
  color: green;
}

.menu-aside .submenu {
  margin-left: 44px;
  display: none;
}

.menu-aside .submenu a {
  color: #6c757d;
  padding: 5px;
}

.menu-aside .submenu a:hover {
  color: #000;
}

.menu-aside .menu-item.active .submenu {
  display: block;
}

.menu-aside .menu-item.has-submenu>.menu-link:after {
  display: inline-block;
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
  margin-top: 0.6em;
  vertical-align: middle;
  content: "";
  border-top: 5px solid #adb5bd;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.nav-pills .nav-link {
  color: #6c757d;
  font-weight: 500;
}

.nav-pills .nav-link:hover {
  color: #141432;
  background-color: rgba(173, 181, 189, 0.15);
}

/* --------------------- titles ---------------------- */
.section-heading {
  margin-bottom: 40px;
  margin-top: 0;
}

.section-heading p {
  max-width: 600px;
  margin: auto;
}

.section-title {
  font-weight: 500;
}

.title-text {
  margin-top: 45px;
  margin-bottom: 20px;
}

.b {
  font-weight: 600;
}

/* ==================  title helpers ================ */
.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

/* ================== COMPONENTS =================== */
a[class*="card"] {
  color: initial;
}

a[class*="card"]:hover .title {
  color: #4fa607;
}

/* ITEM LIST */
.itemlist {
  border-bottom: 1px solid rgba(108, 117, 125, 0.25);
  align-items: center;
  width: 100%;
  padding: 0.5rem 0.5rem;
}

.itemlist:hover {
  background-color: rgba(49, 103, 235, 0.075);
}

/* ITEMSIDE */
.itemside {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}

.itemside .aside,
.itemside .left {
  position: relative;
  flex-shrink: 0;
}

.itemside .info {
  padding-left: 15px;
  padding-right: 7px;
}

.itemside p {
  margin-bottom: 0;
}

.itemside .title {
  display: block;
  margin-bottom: 5px;
}

.itemside a.title:hover {
  color: #4fa607;
}

a.itemside {
  color: initial;
  text-decoration: none;
}

a.itemside:hover .title {
  text-decoration: underline;
}

/* BASE STYLE FOR PRODUCT ITEM */
[class*="card-product"] {
  border: 1px solid rgba(108, 117, 125, 0.25);
}

[class*="card-product"] p {
  margin-bottom: 0;
}

[class*="card-product"] .img-wrap {
  text-align: center;
  display: block;
  overflow: hidden;
  position: relative;
  background: white;
}

[class*="card-product"] .img-wrap img {
  height: 100%;
  max-width: 100%;
  width: auto;
  display: inline-block;
  object-fit: cover;
}

/* GRID STYLE PRODUCT ITEM */
.card-product-grid {
  margin-bottom: 20px;
}

.card-product-grid .img-wrap {
  border-radius: 0.2rem 0.2rem 0 0;
  height: 220px;
}

.card-product-grid .info-wrap {
  padding: 1rem;
}

.card-product-grid a.title {
  color: #6c757d;
  display: block;
  text-decoration: none;
}

.card-product-grid a.title:hover {
  color: #4fa607;
}

.card-product-grid:hover {
  border-color: #adb5bd;
}

/* LARGE LIST STYLE PRODUCT ITEM */
.card-product-list {
  margin-bottom: 20px;
}

.card-product-list .img-wrap {
  height: 220px;
  border-radius: 0.25rem 0 0 0.25rem;
}

.card-product-list .info-main {
  padding: 1.5rem 1rem;
}

.card-product-list .info-aside {
  padding: 1.5rem 1rem;
  border-left: 1px solid rgba(108, 117, 125, 0.25);
  height: 100%;
}

.card-user {
  margin-bottom: 20px;
}

.card-user .card-header {
  position: relative;
  height: 100px;
  background-color: #c3f19d !important;
  text-align: center;
}

.card-user .img-avatar {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 100%;
}

.card-user .card-body {
  text-align: center;
}

/* --------- description list --------*/
[class*="dlist"] {
  margin-bottom: 5px;
}

.dlist-inline dt,
.dlist-inline dd {
  display: inline-block;
}

.dlist {
  display: flex;
}

.dlist dt {
  width: 150px;
  font-weight: normal;
}

.dlist dd {
  margin-left: 30px;
  vertical-align: baseline;
  flex-grow: 1;
  margin-bottom: 0;
  text-align: right;
}

/* -------------------- list.row--------------------- */
ul.row,
ul.row-sm {
  list-style: none;
  padding: 0;
}

/* ================= lists ================= */
[class*="list-"] li:after {
  visibility: hidden;
  display: block;
  content: "";
  overflow: hidden;
  height: 0;
  clear: both;
}

.list-icon {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.list-icon li {
  margin-bottom: 7px;
  position: relative;
  padding-left: 30px;
}

.list-icon .icon {
  width: 22px;
  margin-right: 15px;
  vertical-align: middle;
  text-align: center;
  color: #6c757d;
  position: absolute;
  top: 3px;
  left: 0;
}

.list-icon span {
  vertical-align: middle;
}

.list-bullet {
  list-style: none;
  padding-left: 0;
}

.list-bullet li {
  margin-bottom: 5px;
  position: relative;
  padding-left: 15px;
}

.list-bullet li::before {
  content: " ";
  position: absolute;
  top: 8px;
  left: 0;
  width: 7px;
  border-radius: 100%;
  height: 7px;
  background: #4fa607;
}

.list-check {
  padding-left: 0;
  list-style: none;
}

.list-check>li {
  position: relative;
  padding-left: 24px;
  margin-bottom: 7px;
}

.list-check>li::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  color: #00b517;
  position: absolute;
  top: 2px;
  left: 0;
  content: "";
}

.list-normal {
  list-style: none;
  padding-left: 0;
}

.list-normal li {
  padding-left: 1em;
  margin-bottom: 7px;
}

.list-normal li:before {
  content: "•";
  color: #4fa607;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.list-menu {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.list-menu li {
  margin-bottom: 5px;
}

.list-menu a {
  color: #141432;
}

.list-menu a:hover {
  color: #4fa607;
}

.cols-two {
  column-count: 2;
}

.cols-three {
  column-count: 3;
}

.cols-four {
  column-count: 4;
}

.icontext {
  display: inline-flex;
  align-items: center;
  padding: 5px;
}

.icontext .icon {
  position: relative;
  flex-shrink: 0;
  margin-right: 10px;
}

.icontext small,
.icontext .title {
  display: block;
}

.tag {
  display: inline-block;
  padding: 3px 7px;
  background: #f2f2f2;
  border: 1px xolid #eee;
  border-radius: 3px;
  margin-top: 4px;
  margin-right: 2px;
  font-size: 85%;
}

.price {
  font-weight: 600;
}

.notify {
  position: absolute;
  top: -4px;
  right: -10px;
  display: inline-block;
  padding: 0.25em 0.6em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  border-radius: 3rem;
  color: #fff;
  background-color: #f30000;
}

.map-pin {
  border-radius: 3rem;
  background-color: red;
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.map-pin:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  display: inline-block;
  top: 8px;
  left: 8px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.3);
}

.icon-action {
  margin-top: 5px;
  float: right;
}

/* ====================== box ==================== */
.box {
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(108, 117, 125, 0.2);
  background: #fff;
}

.box img {
  max-width: 100%;
}

/* ================= RATINGS ============== */
.label-rating {
  margin-left: 7px;
  display: inline-block;
  vertical-align: middle;
}

/* rating-stars */
.rating-stars {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  white-space: nowrap;
  clear: both;
  line-height: 0.8;
}

.rating-stars i {
  font-size: 14px;
  color: #ccc;
  display: inline;
}

.rating-stars img {
  height: 16px;
  max-width: none;
}

.rating-stars li {
  display: block;
  text-overflow: clip;
  white-space: nowrap;
  z-index: 1;
}

.rating-stars li.stars-active {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.rating-stars li.stars-active i {
  color: orange;
}

.rating-stars.stars-lg img {
  height: 24px;
  max-width: none;
}

.form-inline input[type="number"] {
  max-width: 100px;
}

.btn-facebook {
  background-color: #405d9d;
  color: #fff;
}

.btn-facebook:hover {
  color: #fff;
}

.btn-instagram {
  background-color: #e52d27;
  color: #fff;
}

.btn-instagram:hover {
  color: #fff;
}

.btn-youtube {
  background-color: #c8046c;
  color: #fff;
}

.btn-youtube:hover {
  color: #fff;
}

.btn-twitter {
  background-color: #42aeec;
  color: #fff;
}

.btn-twitter:hover {
  color: #fff;
}

.btn-google {
  background-color: #ff7575;
  color: #fff;
}

.btn-google:hover {
  color: #fff;
}

.btn-icon {
  text-align: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.btn-icon:hover {
  background-color: rgba(108, 117, 125, 0.15);
}

.btn-icon:focus {
  box-shadow: none;
}

.btn-light {
  background-color: #fff;
  border-color: rgba(108, 117, 125, 0.25);
}

.btn-light i {
  color: #adb5bd;
}

.btn-light:hover {
  background-color: #fff;
  border-color: #adb5bd;
}

.btn-drag {
  padding: 5px 2px;
  display: inline-block;
  cursor: ns-resize !important;
}

.btn-sm i {
  font-size: 1.2rem;
  line-height: 0.7;
  vertical-align: bottom;
}

/* new-sidebar-css */
/* .admin_logo{
  padding: 20px 20px 0 0px;
  margin-bottom: 30px;
} */

.navbar-aside_custom {
  border-right: 1;
}

.navbar-aside_custom .aside-top {
  background-color: #fff;
 
  height: 72px
}

/* .menu-aside .menu-link .text{
  font-size: 15px;
  font-weight: 500;
  color: rgb(255, 255, 255);
} */

/* .menu-aside .menu-item .icon {
  font-size: 18px;
} */
.menu-aside .menu-item .menu-link i {
  font-size: 14px;
}

.searchformcustom .form-control {
  padding: 1rem 1.5rem;
  height: 40px;
  border-radius: 20px;
  background: #f7f8f9;
  position: relative;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.searchformcustom .bt-custom {
  position: absolute;
  top: 0px;
  margin-left: -14px !important;
  right: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 0;
  background-color: #f3711b;
  height: 40px;
  padding: 0 16px;
  font-size: 20px;
}

.searchformcustom .bt-custom i {
  color: #fff;
}

.searchformcustom .bt-custom:hover,
.filter_icon i:hover {
  background-color: #c25710;
}

.filter_icon i:hover {
  background-color: #c25710;
}



.nav-custom .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #f3711b;
  background-color: #fff;
  border-color: #a5a8ab00 #dee2e600 #fff;
  border-bottom: 1px solid #f3711b;
  font-weight: 500;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  color: #c25710;
  border-bottom: 1px solid #f3711b;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 0px);
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  border-radius: 0.2rem;
  width: 40px;
}

.nav-custom .nav-link {
  color: #000;
  font-weight: 500;
}

.model_width {
  max-width: 370px;
}

.table-custom_1 .table {
  font-size: 14px;
  color: #555;
  padding: 10px;
}

.table-custom_1 .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #efefef;
}

.table-custom_1 .table>thead {
  background-color: #efefef;
  height: 45px;
}

.table-custom_1 .table>thead tr th {
  color: #000;
  font-weight: 600;
  font-size: 13px;
}

.top_filter form {
  display: block;
  width: 100%;
  max-width: 500px;
}

.right_pannel label {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.table-custom_1 .table thead th {
  color: #fff;
  font-weight: 400;
}

.col-md-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.table-custom_1 .table td,
.table-custom_1 .table th {
  vertical-align: middle;
}

.table-custom_1 {

  border-radius: 5px;
}

.table-custom_1 .table td,
.table th {
  vertical-align: middle;
  border-top: 0;
  font-size: 13px;
  font-weight: 500;
}

.action-icons i {
  color: #777;
  border-radius: 30px;
  cursor: pointer;
  font-size: 13px;
  margin-left: 9px;
}


.action-icons i:hover {
  color: #ffa318;
}

.table_custom_with {
  max-width: 650px;
}

.filter_icon i {
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  width: 44px;
  height: 44px;
  background: #1488ab;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.form_custom_inner input {
  background-color: #f0f3f8;
  border-radius: 5px;
  border: 0;
  width: 100% !important;
  min-width: 360px !important;
  font-size: 13px;
  height: 40px;
  font-weight: 500;
  padding-right: 36px;
  border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.form_custom_inner input:focus {
  color: #495057;
  background-color: #f0f3f8;
  border-color: #f0f3f8;
  outline: 0;
}

/* .form_custom_inner input :focus {
  background-color: #fff;
  border-color: #d3cac7 !important;
  border-radius: 30px;
} */

.form_custom_inner i {
  color: #f3711b;
}

.page-link {
  color: #333333;
}

.page-item.active .page-link {
  background-color: #f3711b !important;
  border-color: #f3711b !important;
  color: #fff;
}

/* .action-icons i{color:rgb(112, 13, 13);} */

p {
  color: black;
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Responsive-work */
.box,
.media {
  display: flex;
}

.media {
  align-items: flex-start;
}

.dropdown .dropdown-menu.dropdown-menu-right {
  right: 6px !important;
}

.top-toolbar .dropdown .dropdown-menu {
  top: 93% !important;
}

.dropdown .dropdown-menu .dropdown-header,
.dropleft .dropdown-menu .dropdown-header,
.dropright .dropdown-menu .dropdown-header,
.dropup .dropdown-menu .dropdown-header {
  color: #49c016;
  display: block;
  padding: 15px;
  margin-bottom: 0;
  font-size: .875rem;
  white-space: nowrap;

  border-bottom: 1px solid rgba(234, 243, 253, .7);
}

.w-40 {
  width: 40px !important;
}

.MSSG {
  text-align: end;
}

figure,
img {
  max-width: 100%;

  object-fit: cover;

}

.round {
  max-width: 100%;
  object-fit: cover;
  height: 200px;
  border-radius: 50%;
  width: 200px;
}

.align-self-center {
  align-self: center !important;
}

.media-body {
  flex: 1;
}

h5 {
  font-size: 1rem;
}

.media-body span {
  font-size: .75rem;
  font-weight: 400;
}

.dripicons-user:before {
  content: "\e056";
}

[class*=" dripicons-"]:before,
[class^=dripicons-]:before,
[data-icon]:before {
  font-family: dripicons-v2 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.top-toolbar .navbar-nav .nav-item .dropdown-menu-accout .dropdown-item i {
  font-size: 1.5rem;
  margin-right: 8px;
  vertical-align: top;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

i {
  font-style: normal;

}

.dropdown-menu {
  text-align: left;
}

.page-header {
  padding: 30px 10px 10px;
  position: relative;
}

.align-items-center {
  align-items: center !important;
}

.custom-control-input:disabled~.custom-control-label,
.custom-file-label,
.custom-file-label:after,
.form-control,
.form-control:focus {
  color: #323a48;
}

h2 {
  color: #444444;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}

.main-section .card {
  padding: 30px;
}

.drop2 {
  width: 116px !important;
  height: 34px;
  margin-left: 5px;
  border: 1px solid #ebebeb;
  font-size: 13px;
}

.drop1 {
  width: 94px !important;
  height: 41 px;
  margin-left: 5px;
}

.drop {
  width: 50px !important;
  margin-left: 5px;
}

.top_filter {
  padding: 10px 0px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.fileContainer {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 170px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.text-center {
  text-align: center !important;
}

.fileContainer .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #ffffff;
  overflow: hidden;
  width: 100%;
  height: 55px;
  transition: .4s ease;
}

*,
:active,
:focus {
  outline: 0;
}

.fileContainer .text {
  color: rgb(133, 30, 30);
  font-size: 15px;
  position: absolute;
  top: 45%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
}

.fileContainer [type=file] {
  cursor: pointer;
  display: block;
  filter: alpha(opacity=0);
  height: 30%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  bottom: 0;
}

#upload-progress {
  height: 20px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  display: none;
}

#upload-progress .progress {
  height: 18px;
}

.progress {
  background-color: #f3f5f4;
  border-radius: 30px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width .6s ease;
}

.space {
  margin-left: 15px;
  margin-right: 15px;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem rem;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

*,
::after,
::before {
  box-sizing: border-box;
}



.Msg {
  text-align: center;
  color: green;
}

.Emsg {
  text-align: center;
  color: red;
}

.errorclr {
  color: red;
  font-size: 12px;
  ;
}
.error {
  color: red;
  font-size: 14px;
  ;
}

.btn-primary {
  background-color: #48a851 !important;
  border: 1px solid #48a851 !important;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}

/* .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: #000000;
  background-color: #ffa51e !important;
  border-color: #ffa51e !important;
  box-shadow: none;
} */

.disp.dropdown-toggle.btn.btn-primary:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}


.forgetpas {
  text-decoration: none;
  color: #fe534e;
}

.mainbtn {
  margin-top: -120px;
}

.stubtn {
  margin-left: 728px;
}



.innner_content {
  background-color: #fff !important;
  padding: 20px !important;
  max-width: 360px !important;
}

.form-control-custom {
  height: 48px;
  background: #fff;
  color: #000;
  font-size: 16px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, .1);
}

/* .form-control:hover, .form-select:hover {
    border-color: #f3711b;
} */

.nav-link.btn-icon.notification_icon {
  width: 22px;
  height: 22px;
  background: transparent;
  font-size: 18px;
  padding: 0;
}

.setimg {
  display: flex;
  justify-content: center;
}

.user_drpopdown button {
  background: transparent !important;
  border: none !important;
  padding: 0;
  position: relative;
}

.user_drpopdown button:after {
  position: absolute;
  top: 15px;
  right: -10px;
  border-top: 0.3em solid #000;
}

.user_drpopdown button a.nav-link {
  display: flex;
  align-items: center;
}

.user_drpopdown button a.nav-link {
  display: flex;
  align-items: center;
  color: #555;
}

.user_drpopdown button a.nav-link label {
  max-width: 40px;
  height: 40px;
  width: 40px;
  margin-bottom: 0;
}

.user_drpopdown button a.nav-link span {
  display: block;
  color: #555;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 500;
}

.user_drpopdown button:focus {
  box-shadow: none;
  outline: none;
}

.user_drpopdown .dropdown-menu {
  left: auto !important;
  right: 0 !important;
}

.fil {
  display: contents;
  color: red;
  font-size: xx-large;
}

.notification_icon i {
  color: #6c757d;
}

.MuiButton-containedPrimary:hover {
  background-color: #c55306 !important;
}

.confirm_custom-ui.remove_member {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border: 1px solid lightgray;
}



.bgPurple {
  margin-right: 0.5rem !important;
}

.confirm_custom-ui .confirm_btn {
  background: #48a851;
}

.confirm_custom-ui .reject {
  background: red;
}

.create_restaurant .MuiDialog-paperWidthSm {
  max-width: unset;
  display: block;
  max-height: unset;
}

.create_restaurant .MuiDialog-scrollPaper {
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

.file_word {
  font-size: 30px;
}

/* .action-icons i:nth-child(1){color:#009713;}
.action-icons i:nth-child(2){color: rgb(243 113 27);}
.action-icons i:nth-child(3){color: #f00} */
.modal_scroll .form-group {
  margin-bottom: 0;
}

.modal_scroll .form-group label {
  margin-bottom: 0;
  font-size: 14px;
}

.modal_scroll .form-group p {
  font-size: 14px;
  margin-bottom: 0;
}

@media(max-width:992px) {
  .create_restaurant .MuiDialog-scrollPaper {
    overflow-y: scroll;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.input_upload i {
  font-size: 14px;
  margin-top: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  height: 30px;
  position: absolute;
  right: 45px;
  width: 30px;
  text-align: center;

}

.input_upload input {
  opacity: 0;
  position: absolute;
  top: 0
}

.icofont-pencil-alt-2:before {
  content: "\ebf6";
}

.input_uploadd {
  font-size: 14px;
  margin-top: 5px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  height: 30px;
  position: absolute;
  right: 48px;
  width: 30px;
  text-align: center;
}

.input_uploadd input {
  opacity: 0;
  position: absolute;
  top: 0
}

.video {
  width: 100%;
  height: 150px;
}

.table_Icon {
  margin-bottom: 0;
}

.course_show {
  cursor: pointer;
}

.course_color:hover {
  text-decoration: underline;
  color: red;
}

.date {
  display: block;
}

.select_date {
  max-width: 480px;
}

.select_date span,
.select_date input {
  font-size: 14px;
}

.ppimage {
  margin-top: 17px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid;
  background: green;
  object-fit: none;
}

.mbtn {
  margin-left: 10px;
}


.course_name {
  margin-top: 30px;
}

.course_name p,
.select-custom-file-content p {
  font-size: 14px;
  color: #555;
}

.course_name h2 {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 0;
}

.select-custom-file {
  max-width: 300px;
  width: 100%;
  cursor: pointer;
  flex: 0 0 300px;
  border: 2px dashed #ccc;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
}

.select-custom-file .select {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0px;
  left: 50%;
  right: 0px;
  transform: translate(-50%);
  opacity: -0;
}

.content {
  margin-left: 40px;
}

.content p {
  font-size: 18px;
  line-height: 22px;
  color: #000;
}

.select-custom-file-content {
  margin-left: 30px;
}

.select-custom-file-content h4 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0;
}

/* Login Page CSS */
/* .login_logo{ */
/* height: 60px !important; */
/* margin-top: 10px !important; */
/* } */

.login_sec {
   /* background: url(/src/assets/image/banner.jpg) no-repeat center center;  */
  /* background: linear-gradient(67deg, rgba(71,168,81,1) 0%, rgba(0,47,112,1) 100%); */
  /* background-size: cover;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative; */
}



/* .login_sec::before {
  content: "";
  background-color: #000000;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.5;
} */
.login_sec {
  background: url(/src/assets/image/banner.jpg) no-repeat center center / cover;
  height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
}
.login_sec:before {
  content: '';
  background: linear-gradient(180deg, rgba(72, 168, 81, 0.9) 0%, rgba(0, 47, 112, 0.8) 100%);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
/* Login Page CSS */

/* Confirm delete dialog box start */

.confirm_custom-ui {
  background: #fff;
  padding: 40px 70px;
  border-radius: 4px;
  min-width: 410px;
  text-align: center;
}

.modal-dialog {
  margin: 0 auto;
}

.confirm_custom-ui h1 {
  font-size: 29px;
  text-transform: capitalize;
  color: #000;
  margin-bottom: 0;
}

.confirm_custom-ui p {
  margin: 10px 0px 20px;
}

/* Confirm delete dialog box end */


.manage_user {
  border: 0;
  border-radius: 8px;
}

.mag_icon {
  position: absolute;
  right: 10px;
  top: 11px;
  z-index: 9;
  color: #555 !important;
  font-size: 16px;
}

.react-confirm-alert::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  bottom: 0;
  right: 0;
}

.scroll_popup{
  max-height: 500px;
}

.restaurant_timing {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #cccc;
  margin: 20px 0;
  padding-bottom: 10px;
}
.restaurant_table tr th{
  text-align: center;
  border: 1px solid #dfdddd;
}

.form_react {
  font-size: 13px;
  max-width: 170px;
  margin: auto;
  border-color: #e7e7e7;
}

.table_react_form tbody, .table_react_form td, .table_react_form tfoot, .table_react_form th, .table_react_form thead, .table_react_form tr{
  text-align: center;
}

.table_react_form .table td, .table_react_form .table th{
  padding: 6px 13px;
}

.enlarge{
  font-size: 13px;
    color: #d18500;
    text-decoration: underline;
}

.label_upload{font-size: 13px;}
.f_wrap{flex-wrap: wrap;max-height: 200px;overflow: auto;}
.custum_img {
  max-width: 100%;
  height: 90px;
  object-fit: cover;
}
.no_record_text{
  font-size: 18px;
  text-align: center;
}
/* disable pagination dropdown */
.MuiTablePagination-input {
  display: none !important;
}

.field-icon {
  float: right;
  margin-right: 15px;
  margin-top: -36px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  color: #48a851;
}

.spinner_overlay{
  background-color: #000;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0.6;
  overflow-y: hidden;
}
.spinner-box {
  position: fixed;
  left: 50%;
  top: 50%;  
  z-index: 12; 
}


.loading-icon {
  display: flex;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #48a851; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  animation-name: spin;
  animation-duration: 2s;
  transition-timing-function: linear;
  animation-iteration-count: infinite;
  animation: spin 2s linear infinite;
  position: relative;
}

.loading-icon div {
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.field-icon2 {
  float: right;
  margin-right: 15px;
  margin-top: -28px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  color: #3565cd;
}
label.required::after {
  content: "*";
  color: rgb(219, 22, 22);
  padding: 5px;
  font-size: 17px;
  vertical-align: middle;
}
.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 300px;  /* change this to whatever you want */
    width: auto;
}

.close_icon{
  font-size: 24px;
    cursor: pointer;
}
.dash_box {
  border: 0;
  position: relative;
  cursor: pointer;
}

.dash_box::before {
  content: "";
  width: 4px;
  height: 100%;
  background-color: #d99a29;
  position: absolute;
  left: 0;
  top: 0;
}

.dash_box .card-body {
  padding: 30px 24px;
  width: 100%;
  box-shadow: 1px 1px 3px #0000005e;
  border: 0;
  background-color: #fffaf1;
}

.dash_box .card-body i {
  width: 90px;
  height: 90px;
  background-color: #d99a29;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}

.dash_box h5{
  font-size: 24px;
    color: #000000;
    margin-bottom: 5px !important;
    font-weight: 600;
}
.dash_no{
  color: #333333;
  font-size: 36px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bg_green .card-body{
  background-color: #eefff7 !important
}

.bg_green .card-body i {
  background-color: #198754 !important;
}

.bg_green::before {
  background-color: #198754 !important;
}

.bg_purple .card-body{
  background-color: #f8f3ff !important
}

.bg_purple .card-body i {
  background-color: #6200ea !important;
}

.bg_purple::before {
  background-color: #6200ea !important;
}

.flex_d{
  flex-direction: column;
    justify-content: end !important;
    align-items: end !important;
}


/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.custom_upload{
  min-width: 590px;
    padding: 20px 20px;
}

.custom_upload h1{font-size: 24px;}
.ulpoad_box{display: flex;flex-wrap: wrap; gap: 20px;}
.ulpoad_box figure{position: relative;}


.del_ico {
  position: absolute;
  right: -11px;
  background-color: red;
  color: #fff;
  border: 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  top: -9px;
}
.download{
  background-color: #48a851;
    border: none;
    color: white !important;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 20px;
    text-decoration: none !important;
}

.urllink{
  color: #48a851;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
}
.breadcrumb-title {
  font-size: 20px;
  border-right: 1.5px solid #aaa4a4;
}

.page-breadcrumb .breadcrumb li.breadcrumb-item {
  font-size: 20px;
  color: #48a851 ;
}
.load-table-data .table>tbody tr th {
  color: #000;
  font-weight: 600;
  font-size: 13px;
}
.brad{
  background-color: white !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #48a851  !important;
}

.box_custom_1 {
  border-radius: 16px;
  border: 1px solid var(--extended-gray-100, #f4f4f5);
  background: var(--default-white, #fff);
  box-shadow: -2px 20px 50px -10px rgba(88, 92, 98, 0.08);
  padding: 16px 20px;
}

.edu-comment{
  border-radius: 16px;
  border: 1px solid #dfdfdf;
  background: #f5f5f5a3;
  box-shadow: -2px 20px 50px -10px rgba(88, 92, 98, 0.08);
  padding: 20px 20px;
}

.edu-comment + .edu-comment {
  border-top: 1px solid #EEEEEE;
  padding-top: 30px;
  margin-top: 30px;
}
.edu-comment .thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 15px;
}
.edu-comment .thumbnail img {
  /* border-radius: 100%;
  width: 100%; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.edu-comment .comment-content .comment-top {
  display: flex;
  align-items: center;
}
.comment-top .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0px;
  margin-right: 0;
}
._3LWZlK{
  line-height: normal;
    display: inline-block;
    padding: 2px 4px 2px 6px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    vertical-align: middle;
    cursor: pointer;
    background-color: #48a851;
    color: #fff;
}
._3LWZlK2{
  line-height: normal;
    display: inline-block;
    padding: 2px 4px 2px 6px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    vertical-align: middle;
    cursor: pointer;
    width: 100px;
  
}
._1wB99o {
  margin: -2px 0 2px 2px;
 font-size: 8px;
}
.bg-yewllo{
  background-color: yellow;
  color: #000;
}
.bg-green{
  background-color: green;
  color: #fff;
}
.bg-red{
  background-color: red;
  color: #fff;
}
.serch_filter input{
min-width: auto !important;
}
.n-bar-cutom{
  height: calc(100vh - 70px);
  overflow-y: scroll;
}

@media(max-width:1400px){
  .dash_box h5{font-size: 18px;}
  .dash_no {
    font-size: 24px;
}
}

.haram{
font-weight: bold !important;
  color: red !important;
}
.halal{
  font-weight: bold !important;
  color: green !important;
}
.mushbooh{
  font-weight: bold !important;
  color: #B8860B !important;
}
.allergen{
  font-weight: bold !important;
  color: #0197f6 !important;
} 

.haram1{
 
    background-color: red !important;
    color: white !important;
  }
  .halal1{
    
    background-color: green !important;
    color: white !important;
  }
  .mushbooh1{
    
    background-color: #B8860B !important;
    color: white !important;
  }
  .allergen1{
    
    background-color: #0197f6 !important;
    color: white !important;
  } 

  .ms-panel{
    background: #fff;
    box-shadow: 0 1px 6px 1px rgba(69, 65, 78, .1);
    margin-bottom: 30px;
  }
  .ms-panel-header{
    position: relative;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }
  .ms-panel-body{
    position: relative;
    padding: 1.5rem;
  }
  .tstbtn{
    font-size: 14px;
    outline: none;
    padding: .625rem 1rem;
    min-width: 120px;
  }
  .ms-panel-header h6 {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 700;
}

.property_details {
  box-shadow: 3.346px 3.716px 22.5px #00000012;
  overflow: hidden;
  border-radius: 8px;
  margin: 20px 15px 30px;
}
.product_lisitng .property_details {
  margin: 0px 0px 30px;
}
.property_info {
  padding: 20px;
}
.location {
  color: #555;
  font-size: 15px;
}
.location i {
  font-size: 18px;
}
.property_info h3 {
  font-size: 24px;
  margin: 8px 0px;
  color: #0068b2;
}
.property_info p {
  color: #555;
  line-height: 22px;
  font-weight: 300;
}

.icons_room .pro_info {
  max-width: 33.333%;
  flex: auto;
  display: flex;
  gap: 13px;
  font-size: 15px;
  align-items: center;
  position: relative;
}
.icons_room .pro_info img {
  width: 24px;
}
.price_div {
  border-top: 1px solid #ddd;
  margin-top: 25px;
  padding-top: 15px;
}

.price_div .btn-blue {
  background: transparent;
  color: #000;
  border: 1px dashed #ccc;
  text-transform: uppercase;
  padding: 10px 30px;
}

.sections{
  padding: 4rem 2rem;
  text-align: center;

}

.sections .errorss{
  font-size: 150px;
  color: #48a851;
  text-shadow: 
    1px 1px 1px #48a851,    
    2px 2px 1px #48a851,
    3px 3px 1px #48a851,
    4px 4px 1px #48a851,
    5px 5px 1px #48a851,
    6px 6px 1px #48a851,
    7px 7px 1px #48a851,
    8px 8px 1px #48a851,
    25px 25px 8px rgba(0,0,0, 0.2);
}

.pagess{
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.back-home{
  display: inline-block;
  border: 2px solid #48a851;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 15px 15px -11px rgba(0,0,0, 0.4);
  background: #48a851;
  border-radius: 6px;
}
.back-home:hover{
  background: #48a851;
  color: #ddd;
}


.picon {
  font-size: 14px;
  width: 25px;
  color:  #ffffff;;
}


  